(function($) {
    'use strict';

    // UICtrl module which is the controller for anything rendering/event listening in the UI

    var UICtrl = (function() {

        //Declare private variables and functions - not accessible outside 
        var UISelectors = {
            primaryNav              : $('.c-nav-primary'), 
            navicon                 : $('.c-navicon'),
            naviconInner            : $('.c-navicon__inner'),
            menuItemParent          : $('.menu__item--has-children > a'),
            subMenu                 : $('.sub-menu'),
            headerButton            : $('.c-page-header__button-container'),
            headerOffcanvas         : $('.c-nav-header__offcanvas'),
            headerOffcanvasLogo     : $('.c-nav-header__offcanvas-logo'),
            pageHeader              : $('.c-page-header'),
            naviconText             : $('.c-navicon__text'),
            headerText              : $('.c-page-header__text'),
            headerLogoWhite         : $('.c-page-header__logo--large--white'),
            headerLogoPrimary       : $('.c-page-header__logo--large--primary'), 
            headerLogoWhiteMobile   : $('.c-page-header__logo--small--white'),
            headerLogoPrimaryMobile : $('.c-page-header__logo--small--primary'),
            headerLogoSmall         : $('.c-page-header__logo--small'),
            mainContent             : $('.s-page-wrap'),
            menuClose               : $('nav-is-active'),
            main                    : $('.s-main'),
            body                    : $('body'),
          

        };

        var UIHelpers = {
            bookingMenuActive : 'booking-menu-active',
            isActive         : 'is-active',
            menuActive        : 'menu-is-active',
            isOffsetTop      : 'is-offset-top',
            navIsActive      : 'nav-is-active', 
            breakPoints      : [425, 768, 1024]
        }; 

        var menuToggleActivator = function() {

            UISelectors.navicon.on('click', function(e) {
                UISelectors.primaryNav.toggleClass(UIHelpers.isActive); 
                UISelectors.naviconInner.toggleClass(UIHelpers.isActive); 
                UISelectors.mainContent.toggleClass(UIHelpers.isActive);
                UISelectors.body.toggleClass(UIHelpers.menuActive)    ;
                //e.preventDefault(); 
            });

            UISelectors.mainContent.on('click', function(e) {

                UISelectors.primaryNav.removeClass(UIHelpers.isActive);
                
                UISelectors.mainContent.removeClass(UIHelpers.isActive);
                
                UISelectors.naviconInner.removeClass(UIHelpers.isActive); 

                //e.preventDefault();
            });

        };

        var subMenuActivator = function() {

                UISelectors.menuItemParent.on('click', function(e) {

                    if($(window).width > UIHelpers.breakPoints[2]) {

                        UISelectors.subMenu.removeClass(UIHelpers.isActive);
                    }
                    
                    if($(window).width() < UIHelpers.breakPoints[2]) {
                        $(this).parent().find(UISelectors.subMenu).toggleClass(UIHelpers.isActive); 
                        $(this).toggleClass(UIHelpers.isActive);
                        e.preventDefault(); 
                    }
                    
                });            
        };

        var headerButtonActivator = function() {
            
            UISelectors.headerButton.on('click', function(e) {

                UISelectors.headerOffcanvas.toggleClass(UIHelpers.isActive);
                UISelectors.body.toggleClass(UIHelpers.bookingMenuActive)  

                if($(window).width() <= 639) {
                    UISelectors.pageHeader.toggleClass(UIHelpers.isActive);

                    UISelectors.naviconText.toggleClass(UIHelpers.isActive); 
                }

                if($(window).scrollTop() >= 100) {
                    UISelectors.headerOffcanvasLogo.hide();

                } else {
                    UISelectors.headerOffcanvasLogo.show();
                }

                //e.preventDefault(); 
            });

            UISelectors.main.on('click', function(e) {
                
                UISelectors.headerOffcanvas.removeClass(UIHelpers.isActive); 

                //e.preventDefault();
            });

            $(window).scroll(function() { 

                var scroll = $(window).scrollTop();
                 
                if (scroll >= 30) {
                
                    UISelectors.headerOffcanvas.removeClass(UIHelpers.isActive); 
                }
            }); 
        };

        var stickyHeaderActivator = function() {

           $(window).scroll(function() {

                if($(window).scrollTop() >= 100) {
                    
                    UISelectors.pageHeader.addClass(UIHelpers.isOffsetTop);

                    UISelectors.naviconText.addClass(UIHelpers.isOffsetTop); 

                    UISelectors.headerText.addClass(UIHelpers.isOffsetTop); 

                    UISelectors.headerLogoWhite.addClass(UIHelpers.isOffsetTop); 

                    UISelectors.headerLogoPrimary.addClass(UIHelpers.isOffsetTop); 

                    UISelectors.headerLogoPrimaryMobile.addClass(UIHelpers.isOffsetTop); 

                    UISelectors.headerLogoWhiteMobile.addClass(UIHelpers.isOffsetTop); 
                    

                } else {

                    UISelectors.pageHeader.removeClass(UIHelpers.isOffsetTop);

                    UISelectors.naviconText.removeClass(UIHelpers.isOffsetTop); 

                    UISelectors.headerText.removeClass(UIHelpers.isOffsetTop); 

                    UISelectors.headerLogoWhite.removeClass(UIHelpers.isOffsetTop); 

                    UISelectors.headerLogoPrimary.removeClass(UIHelpers.isOffsetTop); 

                    UISelectors.headerLogoPrimaryMobile.removeClass(UIHelpers.isOffsetTop); 

                    UISelectors.headerLogoWhiteMobile.removeClass(UIHelpers.isOffsetTop); 
                }

           });
            
        };

        // Declare public variables and functions - Return private vars and functions specified above. 

        return {
            initMenuToggle: function() {
                menuToggleActivator();
            },
            initSubMenuToggle: function() {
                subMenuActivator();
            },
            initOffcanvasButtonToggle: function() {
                headerButtonActivator();
            }, 
            initStickyHeader: function() {
                stickyHeaderActivator(); 
            }
        };

    })(); 



    UICtrl.initMenuToggle();
    UICtrl.initSubMenuToggle();
    UICtrl.initOffcanvasButtonToggle();
    UICtrl.initStickyHeader();

   

})(jQuery);